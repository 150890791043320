<template>
    <v-dialog v-model="dialog" width="600">
        <v-card shaped>
            <v-card-title class="primary white--text">
                <h3>Cupo</h3>
            </v-card-title>
            <v-card-text class="pa-3">
                <v-container fluid>
                    <CupoForm 
                        ref="cupo-form"
                        :loading="loading" 
                        v-model="cupoData" 
                        :default="defaultCupoData" 
                        :valid.sync="isCupoDataValid" 
                        :context="context" 
                        :owner-context="ownerContext" 
                        :owner="owner" 
                        :has-limit="hasLimit" 
                        :divisa="divisa"
                        />
                </v-container>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="primary" text :disabled="loading" @click="reset()">RESETEAR</v-btn>
                <v-btn color="primary" :disabled="!isValid || loading" :loading="loading" @click="submit()">
                    <v-icon>mdi-content-save</v-icon>
                    <span v-if="this.cupo">Editar cupo</span>
                    <span v-else>Añadir cupo</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import CupoForm from "./form"

import CupoService from '@services/comercio/CupoService';

export default {
    components: {
        CupoForm,
    },
    props: {
        'cupo': {
            required: true,
        },
        'open': {
            required: true,
        },
        'ownerContext': {
            required: true,
        },
        'owner': {
            required: true,
        },
        'hasLimit': {
            default: false,
        },
        divisa: {
            type: Object,
        }
    },
    data: () => ({
        loading: false,
        dialog: false,

        context: 'new',

        cupoData: {},
        defaultCupoData: null,
        isCupoDataValid: false,

        hasUser: false,
    }),
    mounted: function () {
        this.reset();
    },
    computed: {
        isValid: function () {
            let isCupoDataValid = this.isCupoDataValid;
            let isUserDataValid = false;
            if (this.hasUser) {
                isUserDataValid = this.isUserDataValid;
            } else {
                isUserDataValid = isCupoDataValid;
            }
            return this.cupo ? isCupoDataValid || isUserDataValid : isCupoDataValid && isUserDataValid;
        }
    },
    methods: {
        close: function () {
            this.$emit('update:open', false);
        },
        submit: function () {
            this.loading = true;
            let action = null;
            const data = {};

            if (this.isCupoDataValid && Object.keys(this.cupoData).length) data['cupo'] = this.cupoData;

            if (this.cupo) action = CupoService.update(this.ownerContext, this.cupo.id, data);
            else action = CupoService.create(this.ownerContext, {
                cupo: {
                    ... this.cupoData,
                    ... this.owner,
                    divisa_id: this.divisa.id
                }
            });

            action
                .then(({ cupo }) => {
                    this.$ui.notificate({message: 'Cupo guardada'});
                    this.emit(cupo);
                    this.close();
                })
                .catch(() => this.$ui.notificate({message: 'Error guardando el cupo', color: 'error'}))
                .finally(() => this.loading = false);
        },
        emit: function (data) {
            this.$emit('saved', data);
        },
        reset: function () {
            if (this.cupo) {
                this.context = 'edit';
                this.defaultCupoData = { ... this.cupo, comision: this.cupo.comision, banqueo: this.cupo.banqueo };
            } else {
                this.context = 'new';
                this.defaultCupoData = {};
            }
            this.cupoData = {};
        }
    },
    watch: {
        open: function (open) {
            this.reset();
            this.dialog = open;
        },
        dialog: function (dialog) {
            if (!dialog) {
                this.$emit('update:open', false);
                this.reset();
            }
            if (dialog) {
                this.$refs['cupo-form']?.getCupoParent();
            }
        },
    }
}
</script>
